// i18next-extract-mark-ns-start tours_page
import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ToursHeaderSection from "../components/tours/header-section"
import ToursCalendarSection from "../components/tours/calendar-section"

import { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Dispatch } from '../store/store';
import { useTranslation } from "gatsby-plugin-react-i18next"

const ToursPage = () => {
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch.toursCalendar.initialize({translate:t});
  }, [])

  return (
    <Layout>
      <Seo title={t("Kalendarz rejsów")} />
      <ToursHeaderSection />
      <ToursCalendarSection />
    </Layout>
  )
}

export default ToursPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns:{in: ["navigation","footbar", "tours_page", "tours_page_header", "tours_calendar", "boat_types", "tours_list_desktop","tours_list_mobile", "tours_list_button_desktop", "tours_list_button_mobile", "tours_alerts"]},language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;