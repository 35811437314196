// i18next-extract-mark-ns-start tours_page_header
import * as React from "react"
import PageSection from "../page-section"
import { Trans } from "gatsby-plugin-react-i18next"

const ToursHeaderSection = () => {
    return (
        <PageSection>
            <h1 className="pb-3"><Trans>Kalendarz rejsów</Trans></h1>
            <p>
                <Trans>Wybierz  interesujący Cię rejs spośród dostępnych dat, statków oraz tematów</Trans>.
            </p>
            <p>
                <Trans>Rejsy odbywają się do końca października 2022r. <strong>Rezerwacje przyjmujemy na najbliższe 2 tygodnie</strong></Trans>.
            </p>
        </PageSection>
    )
}

export default ToursHeaderSection