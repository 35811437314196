import * as React from "react"
import ToursCalendarList from "../tour-calendar-list/tour-calendar-list";
import PageSection from "../page-section";
import ClientOnly from "../client-only";

const ToursCalendarSection = () => {

    return (
        <PageSection id="toursCalendarSection" className="bg-white text-black position-relative">
            <ClientOnly>
                <ToursCalendarList />
            </ClientOnly>
        </PageSection>
    )
}

export default ToursCalendarSection